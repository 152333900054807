import React from "react";

import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { InputLabel } from "@mui/material";

const SearchInput = (props) => {
  return (
    <Stack spacing={2} sx={{ width: 300 }}>
      <Autocomplete
        size="small"
        freeSolo
        id="free-solo-2-demo"
        fullWidth={props.fullWidth}
        disableClearable
        options={props.options} // Pass the entire array of objects
        getOptionLabel={(option) => (option ? option.country_name : "")} // Define how to display the label
        value={props.value} // Set the selected value
        onChange={(event, newValue) =>
          props.onChange({ target: { name: props.name, value: newValue } })
        } // Pass the selected object back to the parent component
        renderInput={(params) => (
          <>
            <InputLabel htmlFor="search-input" style={{ fontSize: "12px" }}>
              Country
            </InputLabel>
            <TextField
              style={{ width: props.width }}
              {...params}
              InputProps={{
                ...params.InputProps,
                type: "search",
              }}
            />
          </>
        )}
      />
    </Stack>
  );
};

export default SearchInput;
