import React from "react";
import "../navigation/css/navigationbar.css";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GlobalACReusable = (props) => {
  return (
    <div>
      <Typography sx={{ fontSize: "12px", paddingBottom: "5px" }}>
        {props.header}
      </Typography>
      <Autocomplete
        className="global-autocomplete"
        size={props.size}
        value={props.value}
        // multiple
        width={props.width}
        options={props.options}
        onChange={(event, selectedValues) => props.onChange(selectedValues)}
        // disableCloseOnSelect
        getOptionLabel={(option) => option.tour_name}
        renderOption={(props, option) => <li {...props}>{option.tour_name}</li>}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              classes: {
                notchedOutline: "white-border", // Custom class for notchedOutline
              },
            }}
          />
        )}
      />
    </div>
  );
};

export default GlobalACReusable;
