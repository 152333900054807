import React, { useEffect, useRef, useState } from "react";
import { handleChange } from "../../addtours/action/AddTourAction";
import { useSelector, useDispatch } from "react-redux";
import { getData, postData, putData } from "../../../api/api";
import { CryptoJSAesEncrypt } from "../../../api/encryption";
import Swal from "sweetalert2";

const VisaHooks = () => {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [pdfs, setPdfs] = useState([]);
  console.log(pdfs);
  const selectedPDF = useSelector((state) => state.AddTourReducer.selectedPDF);
  const data = useSelector((state) => state.AddTourReducer);
  let selectedCountryId = useSelector(
    (state) => state.AddTourReducer.selected_country
  );

  const onChangeText = (e) => {
    console.log(e);
    let name = e.target.name;
    let value = e.target.value;
    const details = {
      [name]: value,
    };
    dispatch(handleChange(details));
  };
  useEffect(() => {
    getData("countries/").then((res) => {
      const country_list = res.data;
      dispatch(handleChange(country_list));

      if (selectedCountryId) {
        getData(`get_documents/${selectedCountryId.id}`).then((res) => {
          setPdfs(res.data);
          console.log(res.data);
        });
      }
    });
  }, [selectedCountryId]);

  const handleUploadPdfClick = () => {
    fileInputRef.current.click();
  };
  const handleFileSelect = (e) => {
    if (!data.selected_country) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select a country first.",
      });
      return;
    }

    const selectedPDF = Array.from(e.target.files);
    const filePreview = selectedPDF.map((file) => ({
      name: file.name,
      url: file.type.startsWith("image/")
        ? URL.createObjectURL(file)
        : "src/assets/unknown.png",
    }));

    const details = {
      selectedPDF,
      filePreview,
    };
    dispatch(handleChange(details));
  };
  const handleResetFields = () => {
    let details = {
      filePreview: [],
    };
    dispatch(handleChange(details));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    selectedPDF.forEach((file, index) => {
      formData.append("files", file);
    });
    let jsonData = {
      selected_country_id: data.selected_country.id,
    };
    formData.append("data", CryptoJSAesEncrypt(JSON.stringify(jsonData)));
    if (formData.has("files")) {
      postData("upload_pdf/", formData).then((res) => {
        console.log(res.data);
        if (res.status == !200) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred. Please check your input.",
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Form submitted successfully!",
          });
          handleResetFields();
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select at least one file to upload.",
      });
    }
  };

  const handleDeleteItem = async (pdfId) => {
    console.log(pdfId);
    try {
      const response = await putData(`get_documents/${pdfId}/`);

      if (response.status === 200) {
        const updatedPdfs = pdfs.filter((pdf) => pdf.id !== pdfId);
        setPdfs(updatedPdfs);
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to soft-delete item.",
        });
      }
    } catch (error) {
      console.error("Error soft-deleting item:", error.message);
    }
  };
  return {
    onChangeText,
    handleFileSelect,
    handleUploadPdfClick,
    fileInputRef,
    handleSubmit,
    handleResetFields,
    pdfs,
    handleDeleteItem,
  };
};

export default VisaHooks;
