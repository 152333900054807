import React, { useEffect, useState } from "react";
import "../navigation/css/navigationbar.css";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const theme = createTheme({
  components: {
    GlobalACReusable: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            border: "1px solid #e1e5ee", // Set the default border color
          },
          "&:hover fieldset": {
            border: "none", // Remove the hover outline
          },
          "&.Mui-focused fieldset": {
            border: "1px solid #e1e5ee", // Set the focus border color
          },
        },
      },
    },
  },
});

const AutoCompleteReusable = (props) => {
  return (
    <div>
      <Typography sx={{ fontSize: "12px", paddingBottom: "5px" }}>
        {props.header}
      </Typography>
      <ThemeProvider theme={theme}>
        <Autocomplete
          className="custom-autocomplete"
          size={props.size}
          multiple
          width={props.width}
          options={props.options}
          onChange={(event, selectedValues) => props.onChange(selectedValues)}
          // disableCloseOnSelect
          getOptionLabel={(option) => option.type}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.type}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                classes: {
                  notchedOutline: "white-border", // Custom class for notchedOutline
                },
              }}
            />
          )}
        />
      </ThemeProvider>
    </div>
  );
};

export default AutoCompleteReusable;
