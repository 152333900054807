import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleChange } from "../action/AddTourAction";
import { getData, postData } from "../../../api/api";
import { CryptoJSAesEncrypt } from "../../../api/encryption";
import Swal from "sweetalert2";

const AddTourHook = () => {
  const fileInputRef = useRef(null);
  const { id } = useParams();
  const [filteredOptions, setFilteredOptions] = useState([]);
  let filtered_data = JSON.parse(localStorage.getItem("filtered_data"));
  if (filtered_data === null) {
    filtered_data = [];
  }
  const [selectedImages, setSelectedImages] = useState([]);
  const [mydata, setMyData] = useState([]);
  const selectedFiles = useSelector(
    (state) => state.AddTourReducer.selectedFiles
  );

  const data = useSelector((state) => state.AddTourReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedOptions, setSeletedOptions] = useState([]);

  const onChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    const details = {
      [name]: value,
    };

    dispatch(handleChange(details));
  };

  const handleUploadImageClick = () => {
    fileInputRef.current.click();
  };
  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const image_preview = selectedFiles.map((file) =>
      URL.createObjectURL(file)
    );

    const details = {
      selectedFiles,
      image_preview,
    };
    dispatch(handleChange(details));
  };
  // useEffect(() => {
  //   const filteredData = filtered_data.filter((item) => item.id === id);
  //   setFilteredOptions(filteredData);
  // }, [id, filtered_data]);

  const handleResetFields = () => {
    let details = {
      tour_name: "",
      tour_description: "",
      selected_category: [],
      selected_tour_type: [],
      group_size_min: "",
      group_size_max: "",
      image_preview: [],
      selectedFiles: [],
      days_count: "",
      night_count: "",
    };
    dispatch(handleChange(details));
  };
  useEffect(() => {
    getData("tour_package/").then((res) => {
      console.log(res.data);
      localStorage.setItem("filtered_data", JSON.stringify(res.data.tours));
    });

    getData("countries/").then((res) => {
      console.log(res.data);
      const country_list = res.data;
      dispatch(handleChange(country_list));
    });
  }, []);

  const handlePostSuccess = (newRecord) => {
    console.log(newRecord);
    let prev_data = JSON.parse(localStorage.getItem("filtered_data"));
    prev_data.push(newRecord.tours);

    localStorage.setItem("filtered_data", JSON.stringify(prev_data));
    dispatch(handleChange({ selected_tour: newRecord.tours }));
  };
  const handleSubmit = (e, details) => {
    e.preventDefault();

    const formData = new FormData();
    selectedFiles.forEach((file, index) => {
      formData.append(`files`, file);
    });

    let jsonData = {
      tour_name: data.tour_name,
      tour_description: data.tour_description,
      selected_tour_type: data.selected_tour_type,
      selected_category: data.selected_category,
      group_size_min: data.group_size_min,
      group_size_max: data.group_size_max,
      night_count: data.night_count,
      days_count: data.days_count,
      selected_country_id: data.selected_country.id,
    };

    formData.append("data", CryptoJSAesEncrypt(JSON.stringify(jsonData)));

    postData("tour_package/", formData)
      .then((res) => {
        const tour_data = res.data.tours;
        const message_response = res.data;
        if (message_response.message == false) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred. Please check your input.",
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Form submitted successfully!",
          });
          const newRecord = res.data;
          handlePostSuccess(newRecord);
          navigate("/setprices");
          handleResetFields();
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const handleAutoCompleteChange = (selectedValues, name) => {
    const details = {
      [name]: selectedValues,
    };
    dispatch(handleChange({ selected_tour_id: "" }));
    getData(`tour_details/?tour_id=${selectedValues.id}`).then((res) => {
      console.log(res.data);
      dispatch(handleChange({ requested_tour: res.data }));
    });

    dispatch(handleChange(details));
  };
  const handleAutoCompleteChange2 = (selectedValues, name) => {
    const details = {
      [name]: selectedValues,
    };

    dispatch(handleChange(details));
  };

  return {
    onChangeText,
    handleFileSelect,
    handleUploadImageClick,
    fileInputRef,
    selectedOptions,
    handleSubmit,
    handleResetFields,
    filtered_data,
    handleAutoCompleteChange,
    handleAutoCompleteChange2,

    // lastAddedRecord,
    // handleImageSelection,
  };
};

export default AddTourHook;
