import * as React from "react";
import "./css/navigationbar.css";
import "./css/globalAC.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { menulist } from "./utils/NavUtils";
import NavigationHook from "../navigation/hooks/NavigationHooks";
import { Outlet, useParams } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import AutoCompleteReusable from "../textfields/AutoCompleteReusable";
import GlobalACReusable from "../textfields/GlobalACReusable";
import { category_list } from "./utils/NavUtils";
import AddTourHook from "../../pages/addtours/hooks/AddTourHook";
import { useDispatch, useSelector } from "react-redux";
import { handleChange } from "../../pages/addtours/action/AddTourAction";

const drawerWidth = 240;

const Navigation = () => {
  // const { tour } = useParams();
  // console.log(tour);
  const dispatch = useDispatch();

  const { ...param } = NavigationHook();
  const { ...param2 } = AddTourHook();
  const data = useSelector((state) => state.AddTourReducer);
  const selected_tour_id = useSelector(
    (state) => state.AddTourReducer.selected_tour_id
  );
  const data2 = useSelector((state) => state.AddTourReducer.requested_tour);

  let selectedTour =
    data.selected_tour !== undefined ? data.selected_tour : null;
  if (selected_tour_id !== "") {
    param2.filtered_data.forEach((element) => {
      if (parseInt(element.id) == parseInt(selected_tour_id)) {
        selectedTour = element;
      }
    });
    param2.handleAutoCompleteChange(selectedTour, "selected_tour");
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" noWrap component="div">
            Dashboard
          </Typography>
          <div className="global-autocomplete-container">
            {param2.filtered_data.length > 0 && (
              <GlobalACReusable
                id="autocompleteInput"
                name="selected_tour"
                value={selectedTour}
                options={param2.filtered_data}
                size="small"
                width={300}
                onChange={(selectedValues) => {
                  if (selectedValues) {
                    param2.handleAutoCompleteChange(
                      selectedValues,
                      "selected_tour"
                    );
                  }
                }}
              />
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Divider />
        <List>
          {menulist.map((item, index) => {
            return (
              <>
                <ListItem
                  key={index}
                  disablePadding
                  sx={{
                    backgroundColor:
                      param.selectedItem === item ? "#e2eafc" : "transparent",
                  }}
                  onClick={() => param.handelRoute(item)}
                >
                  <ListItemButton
                    onClick={() => param.handleItemClick(item.menu)}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.menu} />
                    {param.openCollapse == item.menu ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                </ListItem>
                <Collapse
                  in={param.openCollapse == item.menu ? true : false}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.submenu.map((subItem, subIndex) => {
                      return (
                        <>
                          <ListItemButton
                            key={subIndex}
                            sx={{
                              pl: 4,
                            }}
                            onClick={() => param.handelRoute(subItem)}
                          >
                            <ListItemIcon>{subItem.icon}</ListItemIcon>
                            <ListItemText
                              disableTypography
                              sx={{ fontSize: "14px" }}
                              primary={subItem.submenuItemName}
                            />
                          </ListItemButton>
                        </>
                      );
                    })}
                  </List>
                </Collapse>
              </>
            );
          })}
        </List>
        <Divider />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: "#edf2fb", p: 3 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Navigation;
