import React from "react";
import { Divider, Grid } from "@mui/material";
import TextFieldReusable from "../../components/textfields/TextFieldReusable";
import ButtonReusable from "../../components/buttons/ButtonReusable/ButtonReusable";
import { Document, Page } from "react-pdf";
import "../../components/utils/maincss/main.css";
import "./css/visa.css";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";
import VisaHooks from "./hooks/VisaHooks";
import { useSelector } from "react-redux";
import pdfImage from "../../assets/pdf.png";
import SearchInput from "../../components/select/SearchInput";
import { FaFileDownload } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const renderFile = (file, index) => {
  if (file && file.type && file.type.startsWith("application/pdf")) {
    // Ensure file is a valid PDF
    if (!file.url && !file.data) {
      console.error("Invalid PDF file:", file);
      return null;
    }

    return (
      <Grid item xs={12} md={6} key={index}>
        <div className="pdf-container">
          {/* Pass the correct prop based on how the PDF is stored */}
          {file.url ? (
            <Document file={{ url: file.url }}>
              <Page pageNumber={1} />
            </Document>
          ) : (
            <Document file={{ data: file.data }}>
              <Page pageNumber={1} />
            </Document>
          )}
        </div>
      </Grid>
    );
  } else if (file && file.type && file.type.startsWith("image/")) {
    return (
      <Grid item xs={12} md={6} key={index}>
        <div className="image-container">
          <img className="img" src={file.preview} alt="" />
        </div>
      </Grid>
    );
  }
  return null; // Handle other file types as needed
};
const Visa = () => {
  const { ...param } = VisaHooks();
  const country = useSelector((state) => state.AddTourReducer.country);
  const filePreview = useSelector((state) => state.AddTourReducer.filePreview);
  const selectedPDF = useSelector((state) => state.AddTourReducer.selectedPDF);
  console.log(selectedPDF);
  const reducer_data = useSelector((state) => state.AddTourReducer);
  const countryOptions = useSelector(
    (state) => state.AddTourReducer.country_list
  );
  const selected_country = useSelector(
    (state) => state.AddTourReducer.selected_country
  );
  console.log(selected_country);

  return (
    <div>
      <form onSubmit={param.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="fields-container">
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <div style={{}}>
                      <SearchInput
                        name="selected_country"
                        value={reducer_data.selected_country}
                        placeholder="Country"
                        options={countryOptions}
                        onChange={param.onChangeText}
                        fullWidth={true}
                        width="100%"
                      />
                    </div>

                    <div className="button-container">
                      <input
                        type="file"
                        accept=".pdf*"
                        style={{ display: "none" }}
                        onChange={param.handleFileSelect}
                        ref={param.fileInputRef}
                        multiple
                      />

                      <div className="button-holder">
                        <ButtonReusable
                          buttonName="Select Documents"
                          textTransform="capitalize"
                          backgroundColor="#354259"
                          color="white"
                          padding="8px 18px"
                          startIcon={<AiOutlineCloudUpload />}
                          hoverBackgroundColor="#04364A"
                          onClick={param.handleUploadPdfClick}
                        />
                        <ButtonReusable
                          buttonName="Submit"
                          backgroundColor="#FF9209"
                          hoverBackgroundColor="#FF6C22"
                          color="white"
                          textTransform="capitalize"
                          padding="8px 18px"
                          onClick={param.handleSubmit}
                          type="submit"
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={12}>
                  <Divider sx={{ margin: "20px 0 5px 0", variant: "middle" }} />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div className="title-container">
                        <p style={{ fontWeight: 700 }}>Document files</p>
                      </div>

                      <div className="pdf-list">
                        <div className="list_items">
                          <div className="title">
                            <p style={{ fontWeight: 700 }}></p>
                          </div>
                          {param.pdfs.map((val, index) => {
                            return (
                              <div
                                key={index}
                                style={{ marginBottom: "8px" }}
                                // href={
                                //   "http://192.168.2.252:8000" + val.pdf_file
                                // }
                                // download
                                // target="_blank"
                              >
                                <div className="list-item-container">
                                  <>
                                    <li>{val.pdf_name}</li>
                                    <MdDelete
                                      className="deleteBtn"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        param.handleDeleteItem(val.id)
                                      }
                                    />
                                  </>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider sx={{ margin: "20px 0 5px 0", variant: "middle" }} />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div className="title-container">
                        <p style={{ fontWeight: 700 }}>Files to Upload</p>
                      </div>
                      <div className="files-wrapper">
                        {filePreview.map((val, index) => (
                          <Grid item xs={12} md={12} key={index}>
                            <div className="files-container">
                              <div className="file-holder">
                                <img src={pdfImage} alt="" />
                                <h3>{val.name}</h3>
                              </div>
                              {renderFile(val, index)}
                            </div>
                          </Grid>
                        ))}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Visa;
