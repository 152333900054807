import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleChange } from "../../../pages/addtours/action/AddTourAction";
import { useDispatch } from "react-redux";

const NavigationHooks = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [openCollapse, setOpenCollapse] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleItemClick = (item) => {
    if (item == openCollapse) {
      item = "";
    }
    setOpenCollapse(item);
  };
  const handelRoute = (selectedIndex) => {
    navigate(selectedIndex.route);
    setSelectedItem(selectedIndex);
  };

  return {
    handleItemClick,
    handelRoute,
    selectedItem,
    openCollapse,
  };
};
export default NavigationHooks;
