import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
const TextFieldReusable = (props) => {
  return (
    <div>
      <Typography sx={{ fontSize: "12px", paddingBottom: "5px" }}>
        {props.title} <span style={{ color: "red" }}>{/* <sup>*</sup> */}</span>
      </Typography>
      <TextField
        InputProps={props.InputProps}
        disabled={props.disabled}
        id="outlined-basic"
        label={props.label}
        variant="outlined"
        autoComplete="off"
        size={props.size}
        type={props.type}
        onChange={props.onChange}
        name={props.name}
        value={props.value}
        fullWidth
        required
        placeholder={props.placeholder}
        multiline={props.multiline}
        rows={props.rows}
        sx={{
          padding: props.padding,
          backgroundColor: props.backgroundColor,
          borderRadius: "0px",
          border: props.border,
          color: props.color,
          fontSize: props.fontSize,

          "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#454545", // Customize the onFocus color here
            // borderRadius: "0px",
            // opacity: [0.9, 0.8, 0.7],
          },
          "&:hover": {
            // backgroundColor: "primary.main",
            // opacity: [0.9, 0.8, 0.3],
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderWidth: 0, // Removes border when focused
            },
            "&:hover fieldset": {
              borderWidth: 0, // Removes border on hover
            },
            "& fieldset": {
              borderWidth: 0, // Removes default border
            },
          },
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none",
            },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
          "& input": {
            color: props.textColor, // Change the text color to red
            fontSize: props.textFontSize, // Change the font size to 16px
          },
        }}
      />
    </div>
  );
};

export default TextFieldReusable;
