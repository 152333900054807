import axios from "axios";
var CryptoJS = require("crypto-js");
// let serverurl = "localhost:3001/";
let serverurl = " http://beta.api.cozytravelph.com/api/";
const image_url = "http://beta.api.cozytravelph.com/";

// let serverurl = " http://apiweb.pacificweb.com.ph/";

// let serverurl = "http://192.168.2.252:8000/api/";
// const image_url = "http://192.168.2.252:8000/";

export const serverurlapi = serverurl;

const CancelToken = axios.CancelToken;
let source = CancelToken.source();
export function getData(method, param2) {
  const config = {
    // headers: {
    //   authorization: window.sessionStorage.getItem("token"),
    //   Accept: "application/json",
    //   "Content-Type": "application/json",
    // },
  };
  return new Promise((resolve, reject) => {
    // let param = CryptoJSAesEncrypt(JSON.stringify(param2));
    // console.log(param2);

    axios
      .get(
        serverurl + method,
        param2,

        {
          cancelToken: source.token,
        }
        // config
      )
      .then((res) => {
        // let decrypt = CryptoJSAesDecrypt(res.data);
        // console.log(decrypt);
        resolve(res);
      });
  });
}
export function postData(method, param2) {
  const config = {
    // headers: {
    //   authorization: window.sessionStorage.getItem("token"),
    //   Accept: "application/json",
    //   "Content-Type": "application/json",
    // },
  };
  return new Promise((resolve, reject) => {
    // let param = CryptoJSAesEncrypt(JSON.stringify(param2));
    // console.log(param2);

    axios
      .post(
        serverurl + method,
        // { param2 },
        param2,

        {
          cancelToken: source.token,
        }
        // config
      )
      .then((res) => {
        // let decrypt = CryptoJSAesDecrypt(res.data);
        // console.log(decrypt);
        resolve(res);
      });
  });
}
export function putData(method, param2) {
  const config = {
    // headers: {
    //   authorization: window.sessionStorage.getItem("token"),
    //   Accept: "application/json",
    //   "Content-Type": "application/json",
    // },
  };
  return new Promise((resolve, reject) => {
    // let param = CryptoJSAesEncrypt(JSON.stringify(param2));
    // console.log(param2);

    axios
      .put(
        serverurl + method,
        param2,

        {
          cancelToken: source.token,
        }
        // config
      )
      .then((res) => {
        // let decrypt = CryptoJSAesDecrypt(res.data);
        // console.log(decrypt);
        resolve(res);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}
export function getDataAuth(method, param2, token) {
  const config = {
    headers: {
      authorization: token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return new Promise((resolve, reject) => {
    let param = CryptoJSAesEncrypt(JSON.stringify(param2));
    axios
      .post(
        serverurl + method,
        {
          param,
        },
        config
      )
      .then((res) => {
        let decrypt = CryptoJSAesDecrypt(res.data);
        console.log(res);
        resolve(decrypt);
      });
  });
}
export function loginApi(method, param2) {
  const config = {
    headers: {
      authorization: "Basic d2VzdG1lYWQ6d2VzdG1lYWQyMDIw",
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  return new Promise((resolve, reject) => {
    let param = CryptoJSAesEncrypt(JSON.stringify(param2));
    axios
      .post(
        serverurl + method,
        {
          param,
        },
        config
      )
      .then((res) => {
        let decrypt = CryptoJSAesDecrypt(res.data);
        console.log(res);
        resolve(decrypt);
      });
  });
}
export function uploadFileData(method, param) {
  const config = {
    headers: {
      // authorization: window.sessionStorage.getItem("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return new Promise((resolve, reject) => {
    axios.post(serverurl + method, param, config).then((res) => {
      let decrypt = CryptoJSAesDecrypt(res.data);
      resolve(decrypt);
    });
  });
}
export function getNonTokenData(method, param2) {
  return new Promise((resolve, reject) => {
    let param = CryptoJSAesEncrypt(JSON.stringify(param2));
    axios
      .post(serverurl + method, {
        param,
      })
      .then((res) => {
        console.log(res);
        let decrypt = CryptoJSAesDecrypt(res.data);
        resolve(decrypt);
      });
  });
}

// let appstring = "api/v1/master/Login/PinAuthentication";
let appkey = "0123456789123456";

function CryptoJSAesEncrypt(plain_text) {
  var salt = CryptoJS.lib.WordArray.random(256);
  var iv = CryptoJS.lib.WordArray.random(16);
  var key = CryptoJS.PBKDF2(appkey, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: 64 / 8,
    iterations: 999,
  });
  var encrypted = CryptoJS.AES.encrypt(plain_text, key, { iv: iv });
  var data = {
    ciphertext: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
    salt: CryptoJS.enc.Base64.stringify(salt),
    iv: CryptoJS.enc.Base64.stringify(iv),
  };
  return JSON.stringify(data);
}

function CryptoJSAesDecrypt(encryption) {
  try {
    let param = JSON.parse(encryption);
    let salt = CryptoJS.enc.Base64.parse(param.salt);
    let iv = CryptoJS.enc.Base64.parse(param.iv);
    let ciphertext = param.ciphertext;
    //for more random entropy can use : https://github.com/wwwtyro/cryptico/blob/master/random.js instead CryptoJS random() or another js PRNG
    var key = CryptoJS.PBKDF2(appkey, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: 64 / 8,
      iterations: 999,
    });
    var decrypt = CryptoJS.AES.decrypt(ciphertext, key, { iv: iv });
    let decryptParse = decrypt.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptParse);
  } catch (error) {}
}
export function cancelRequest() {
  source.cancel("Operation canceled by the user.");
  source = CancelToken.source();
}

export default image_url;
