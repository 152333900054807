import { FaHome } from "react-icons/fa";
import { BsTools } from "react-icons/bs";
import { PiLeafLight } from "react-icons/pi";

export const menulist = [
  {
    menu: "Dashboard",
    route: "/",
    icon: <FaHome />,
    submenu: [
      // {
      //   submenuItemName: "Add Tours",
      //   route: "/addtours",
      //   icon: <PiLeafLight />,
      // },
      // {
      //   submenuItemName: "All Tours",
      //   route: "/alltours",
      //   icon: <BsTools />,
      // },
    ],
  },

  {
    menu: "Add Tours",
    route: "",
    icon: <PiLeafLight />,
    submenu: [
      {
        submenuItemName: "Create Tour",
        route: "/create_tour",
        icon: <PiLeafLight />,
      },
      {
        submenuItemName: "Set Prices",
        route: "/setprices",
        icon: <PiLeafLight />,
      },
      {
        submenuItemName: "Add Itinerary",
        route: "/add_itinerary",
        icon: <PiLeafLight />,
      },
      {
        submenuItemName: "Inclusion & Exclusion",
        route: "/add_inclusion_and_exclusion",
        icon: <PiLeafLight />,
      },
    ],
  },

  {
    menu: "All Tours",
    route: "/",
    icon: <BsTools />,
    submenu: [
      {
        submenuItemName: "All Tours",
        route: "/alltours",
        icon: <BsTools />,
      },
    ],
  },
  {
    menu: "Visa",
    route: "/visa",
    icon: <PiLeafLight />,
    submenu: [
      {
        submenuItemName: "Update Visa Details",
        route: "/visa_details",
        icon: <PiLeafLight />,
      },
    ],
  },
];

export const category_list = [
  { type: "Single" },
  { type: "Double" },
  { type: "Tripple" },
  { type: "Other" },
];

export const tour_type_list = [
  { type: "All in" },
  { type: "Land Arrangement" },
  { type: "Domestic" },
  { type: "International" },
  { type: "Other" },
];

export const currency_list = [{ type: "₱" }, { type: "$" }, { type: "฿" }];

export const actionList = [{ type: "Update" }, { type: "Delete" }];
