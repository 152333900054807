import React from "react";
import { ACTION_ADD_TOUR } from "../pages/addtours/constant/Constant";

const initialState = {
  tour_name: "",
  tour_description: "",
  regular_price: 0,
  tour_start: "",
  tour_end: "",
  daysDifference: "",
  nightDifference: "",
  days_count: "",
  night_count: "",
  formattedDate: "",
  suplement: 0,
  tour_category: "",
  tour_type: "",
  group_size_min: "",
  group_size_max: "",
  selectedFiles: [],
  selectedImages: [],
  filesArray: [],
  image_preview: [],
  filePreview: [],
  selectedPDF: [],
  selected_category: [],
  imageArray: [],
  selected_tour_type: [],
  selected_currency: "",
  selected_tour: "",
  itineraries: [],
  inclusions: [],
  exclusions: [],
  selectedItinerary: {
    itinerary: "",
    hotels: "",
    bld: "",
  },
  lastAddedRecord: "",
  requested_tour: [],
  all_tours_data: [],
  single_overview_data: [],
  selected_tour_id: "",
  priceId: "",
  tourId: "",

  //data to update in setprice section

  new_regular_price: "",
  new_currency: "",
  new_tour_start: "",
  new_tour_end: "",
  new_selected_category: "",

  // visa details object
  visa_detail: {
    country: "",
  },
  country: "",
  country_list: [],
  selected_country: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_ADD_TOUR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default dataReducer;
