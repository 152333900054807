import "./App.css";
import React, { Suspense, lazy } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Navigation from "./components/navigation/Navigation";
import Visa from "./pages/visa/Visa";
const AddTours = React.lazy(() => import("./pages/addtours/Add_Tours"));
const SetPrice = React.lazy(() => import("./pages/addtours/SetPrice"));
const Itinerary = React.lazy(() => import("./pages/addtours/Itinerary"));
const InclusionAndExclusion = React.lazy(() =>
  import("./pages/addtours/InclusionAndExclusion")
);
const AllTour = React.lazy(() => import("./pages/alltours/AllTour"));
const UpdateTour = React.lazy(() =>
  import("./pages/alltours/pageComponent/UpdateTour")
);
const TourOverview = React.lazy(() =>
  import("./pages/alltours/pageComponent/TourOverview")
);
const AddVisaDetails = React.lazy(() => import("./pages/visa/AddVisaDetails"));

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Suspense>
          <Routes>
            <Route path="/" element={<Navigation />}>
              <Route path="/create_tour" element={<AddTours />} />
              <Route path="/setprices" element={<SetPrice />} />
              <Route path="/add_itinerary" element={<Itinerary />} />
              <Route path="/alltours" element={<AllTour />} />
              <Route path="/visa" element={<Visa />} />
              <Route path="/visa_details" element={<AddVisaDetails />} />

              <Route
                path="/add_inclusion_and_exclusion"
                element={<InclusionAndExclusion />}
              />

              {/* <Route path="/package_details" element={<Package_Details />} /> */}
            </Route>
            <Route path="/update_tours" element={<UpdateTour />} />
            <Route path="/tour_overview/:id" element={<TourOverview />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </div>
  );
}

export default App;
